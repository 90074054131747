import axios from 'axios';
const API_URL =
  // 'https://drivergpstorage.z9.web.core.windows.net/gp-leaderboard.json';
  // 'https://storage.chargeintosummer.ca/gp-leaderboard.json';
  // 'https://storage.chargeintosummer.ca/drivers_historical.json';
  'https://storage.chargeintosummer.ca/drivers_historical.json';

interface ObjectWithDate {
  date: Date;
}

export const getDrivers = async () => {
  try {
    const result = await axios.get(API_URL);
    // sort the datapoints in case they're not sorted
    // TODO: REMOVE THIS AFTER API HAS CONFIRMED SORTED DATA
    const sortedResult = result.data.map(
      (driver: { dataPoints: ObjectWithDate[] }) => {
        driver.dataPoints.sort((a: ObjectWithDate, b: ObjectWithDate) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);

          return dateB > dateA ? -1 : dateB < dateA ? 1 : 0;
        });
        return driver;
      }
    );

    return sortedResult;
  } catch (error) {
    return error;
  }
};
