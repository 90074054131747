import React from 'react';
import { Driver } from '../api/Driver';
import MaterialTable from 'material-table';
import { injectIntl } from 'react-intl';
import DriverHistoryChart from './DriverHistoryChart';
import Increase from './icons/Increase';
import Decrease from './icons/Decrease';
import CardModule from './CardModule';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import { getLatestDate } from '../utils';

type TParams = { id: string };

type TFilterKey = 'division' | 'centre';

interface Props extends RouteComponentProps<TParams> {
  intl: any;
  drivers: Driver[];
  filterKey?: TFilterKey;
}

const filterDriversByKey = (drivers: Driver[], key: TFilterKey, id: string) => {
  return drivers.filter(driver => {
    return driver[key].toLocaleLowerCase() === id.toLocaleLowerCase();
  });
};

class Leaderboard extends React.Component<Props> {
  getTableData(props: Props) {
    const { drivers, filterKey } = props;
    if (filterKey && this.props.match.params.id) {
      return filterDriversByKey(drivers, filterKey, this.props.match.params.id);
    }
    return drivers;
  }
  render() {
    const { intl, filterKey } = this.props;
    const driverCount = this.props.drivers.length;
    const tableData = this.getTableData(this.props);

    const dateStampText =
      intl.formatMessage({ id: 'app.date.title' }) +
      ' ' +
      intl.formatDate(getLatestDate(this.props.drivers), {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

    return (
      <CardModule
        header={intl.formatMessage({ id: 'leaderboard.title' })}
        icon="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAABnAAAAZwB+oBkCQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACmSURBVDiN5ZO9DcIwFAYvkIqOMRASTVZBjMAMjMAKMAKz0FDRsUK6iAYdRRzFsoj4SwHKVe/5ZEvvs52pc+AEjIEzUFEzAWa0VMHT4W/AArWwpWhssq56jDY/8qrFiJ7p/cA86bdqGeoSWEVuqh6iPvVroDOLVzJL/e4/M9wAl1A/yyz1BbAf4DvMwpjNOPFffheBZWeGn/L7GQ7wwBy4Ul85of6KOxJq22WulabXAAAAAElFTkSuQmC"
        title={dateStampText}
      >
        {filterKey && (
          <Chip
            label={
              intl.formatMessage({ id: 'leaderboard.table.filteredBy' }) +
              this.props.match.params.id
            }
            onDelete={() => {
              this.props.history.push('/');
            }}
            color="secondary"
          />
        )}

        <MaterialTable
          title=""
          columns={[
            {
              title: intl.formatMessage({ id: 'leaderboard.table.rank' }),
              field: 'rank',
              type: 'numeric',
              filtering: false,
              render: rowData => {
                return intl.formatNumber(rowData.rank);
              },
              defaultSort: 'asc',
            },
            {
              title: '+/-',
              field: 'rankingDifference',
              type: 'numeric',
              filtering: false,
              render: rowData => {
                if (rowData.rankingDifference > 0) {
                  return (
                    <div style={{ marginLeft: '-5px' }}>
                      <Increase></Increase>
                      {intl.formatNumber(rowData.rankingDifference)}
                    </div>
                  );
                }
                if (rowData.rankingDifference < 0) {
                  return (
                    <div style={{ marginLeft: '-5px' }}>
                      <Decrease></Decrease>
                      {intl.formatNumber(rowData.rankingDifference)}
                    </div>
                  );
                }
              },
            },
            {
              title: intl.formatMessage({ id: 'leaderboard.table.name' }),
              field: 'name',
              filtering: false,
              render: rowData => {
                return (
                  <Link to={`/drivers/${rowData.id}`}>{rowData.name}</Link>
                );
              },
            },
            {
              title: intl.formatMessage({ id: 'leaderboard.table.centre' }),
              field: 'centre',
              filtering: filterKey !== 'centre',
              render: rowData => {
                return (
                  <Link to={`/centres/${rowData.centre}`}>
                    {rowData.centre}
                  </Link>
                );
              },
            },
            {
              title: intl.formatMessage({ id: 'leaderboard.table.division' }),
              field: 'division',
              filtering: filterKey !== 'division',
              render: rowData => {
                return (
                  <Link to={`/divisions/${rowData.division}`}>
                    {rowData.division}
                  </Link>
                );
              },
            },
            {
              title: intl.formatMessage({ id: 'leaderboard.table.leads' }),
              field: 'leads',
              type: 'numeric',
              filtering: false,
              render: rowData => {
                return intl.formatNumber(rowData.leads);
              },
            },
            {
              title: intl.formatMessage({ id: 'leaderboard.table.volume' }),
              field: 'volume',
              type: 'numeric',
              filtering: false,
              render: rowData => {
                return intl.formatNumber(rowData.volume);
              },
            },
          ]}
          data={tableData}
          options={{
            filtering: false,
            paging: true,
            pageSize: 10,
            showTitle: false,
            padding: 'dense',
            sorting: true,
          }}
          style={{ boxShadow: 'none' }}
          detailPanel={rowData => {
            return (
              <DriverHistoryChart driver={rowData} driverCount={driverCount} />
            );
          }}
          localization={{
            pagination: {
              labelDisplayedRows:
                '{from}-{to} ' +
                intl.formatMessage({ id: 'pagination.labelDisplayedRows' }) +
                ' {count}',
              labelRowsSelect: intl.formatMessage({
                id: 'pagination.labelRowsSelect',
              }),
              labelRowsPerPage: intl.formatMessage({
                id: 'pagination.labelRowsPerPage',
              }),
              //@ts-ignore
              firstAriaLabel: intl.formatMessage({
                id: 'pagination.firstAriaLabel',
              }),
              firstTooltip: intl.formatMessage({
                id: 'pagination.firstTooltip',
              }),

              previousAriaLabel: intl.formatMessage({
                id: 'pagination.previousAriaLabel',
              }),
              previousTooltip: intl.formatMessage({
                id: 'pagination.previousTooltip',
              }),
              nextAriaLabel: intl.formatMessage({
                id: 'pagination.nextAriaLabel',
              }),
              nextTooltip: intl.formatMessage({
                id: 'pagination.nextTooltip',
              }),
              lastAriaLabel: intl.formatMessage({
                id: 'pagination.lastAriaLabel',
              }),
              lastTooltip: intl.formatMessage({
                id: 'pagination.lastTooltip',
              }),
            },
            body: {
              emptyDataSourceMessage: intl.formatMessage({
                id: 'table.body.emptyDataSourceMessage',
              }),
              filterRow: {
                filterTooltip: intl.formatMessage({
                  id: 'table.body.filterRow.filterTooltip',
                }),
              },
            },
            toolbar: {
              searchPlaceholder: intl.formatMessage({
                id: 'table.toolbar.searchPlaceholder',
              }),
              searchTooltip: intl.formatMessage({
                id: 'table.toolbar.searchTooltip',
              }),
            },
          }}
        />
      </CardModule>
    );
  }
}

export default injectIntl(Leaderboard);
