import React from 'react';
import {
  FormattedHTMLMessage,
  injectIntl,
  InjectedIntlProps,
} from 'react-intl';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import VideoBlock from './Video';
import enPDF from '../documents/terms.pdf';
import frPDF from '../documents/terms-fr.pdf';
import { BounceInRight, FadeIn } from './Animations';

const Wrapper = styled.div`
  padding: 2rem 0;
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: 800;
  line-height: 1;
  padding-botom: 0.5rem;
  // font-family: 'UPS Berlingske Serif Tx', Georgia, Times, 'Times New Roman',
  //   serif;
  color: rgba(51, 0, 0, 1);
`;

const Text = styled.div`
  padding: 1rem;
  background: rgba(198, 125, 48, 1);
  color: white;
  font-weight: bold;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const Intro = styled.p`
  font-size: 1rem;
  line-height: 1.25rem;
  @media (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;
const ALink = styled.a`
  text-decoration: underline;
  color: white;
  &:hover {
    color: rgba(239, 239, 239, 1);
  }
`;

const SubHeader: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const doc = intl.locale === 'fr' ? frPDF : enPDF;
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col
            xs={12}
            lg={6}
            className={'order-last order-lg-first d-flex align-items-center'}
          >
            <VideoBlock />
          </Col>
          <Col
            xs={12}
            lg={6}
            className={
              'order-first order-lg-last d-flex flex-column justify-content-around'
            }
          >
            <BounceInRight>
              <Title>
                <FormattedHTMLMessage id="app.title" />
              </Title>
            </BounceInRight>
            <FadeIn>
              <Text>
                <Intro>
                  <FormattedHTMLMessage id="app.intro" />
                </Intro>
                <p>
                  <ALink href={doc} target="_blank" rel="noopener noreferrer">
                    <FormattedHTMLMessage id="terms" />
                  </ALink>
                  <img
                    style={{ paddingLeft: '3px' }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAABSgAAAUoB6YX5ogAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADDSURBVDiNzZK7CQJBFEXvA/sQBHvYyNgGtgUxMzDwkxsIWoFiAVYhhvYgaAMGZgY7x2SEcZ3ZXQPBm82bd8+785H+RsAOePCui99blernl88CQCFpIekYcO9mdgLakrqSxpL6kmRmplCenFcknPueA0CsIQkAloADRkD+FSCYPPHrDrBuBAjM09TRkgBgFjMDPeBaCQCGqcnlO2glAt0kDcxsU5c8CjCzfZ0xBnCSstgLlZRJKj6q/is76uWAbdOEv9cTVRz6r9RpozoAAAAASUVORK5CYII="
                    alt=""
                  />
                </p>
              </Text>
            </FadeIn>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default injectIntl(SubHeader);
