import React from 'react';
import { Driver } from '../api/Driver';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import DriverHistoryChart from './DriverHistoryChart';
import Increase from './icons/Increase';
import Decrease from './icons/Decrease';
import IconDriver from '../images/icon-driver.svg';
import styled from 'styled-components';
import {
  FormattedNumber,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';

const Arrow = styled.span`
  display: inline-block;
  margin-left: -5px;
`;

const Wrapper = styled.div`
  background: white;
  border-radius: 5px;
`;

const DrvierName = styled.h1`
  font-size: 1.75rem;
  font-weight: 700;
  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

type TParams = { id: string };

interface Props extends RouteComponentProps<TParams> {
  drivers: Driver[];
}

const getRankingDifferenceIcon = (rankingDifference: number) => {
  if (rankingDifference > 0) {
    return (
      <Arrow>
        <Increase></Increase>
      </Arrow>
    );
  }
  if (rankingDifference < 0) {
    return (
      <Arrow>
        <Decrease></Decrease>
      </Arrow>
    );
  }
};

const DriverProfile: React.FunctionComponent<Props> = (props: Props) => {
  const { drivers } = props;
  const driverCount = drivers.length;

  const driver = drivers.filter(driver => {
    return driver.id.toString() === props.match.params.id;
  })[0];

  return (
    <div className="pb-4">
      <Breadcrumb>
        <li className="breadcrumb-item">
          <Link to="/">
            <FormattedMessage id="leaderboard.title" />
          </Link>
        </li>
        {driver && <li className="breadcrumb-item active">{driver.name}</li>}
      </Breadcrumb>
      {driver && (
        <Wrapper>
          <Container className="profile">
            <Row>
              <Col className="col-md-5 d-none d-md-block">
                <img
                  src={IconDriver}
                  className="driver-avatar border p-2"
                  alt=""
                />
              </Col>
              <Col className="col-md-7 col-12 mt-md-0 text-left">
                <Row>
                  <Col>
                    <DrvierName>{driver.name}</DrvierName>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-5 col-6 font-weight-bold">
                    <FormattedHTMLMessage id="profile.division" />
                  </Col>
                  <Col className="col-md-7 col-6">{driver.division}</Col>
                </Row>
                <Row>
                  <Col className="col-md-5 col-6 font-weight-bold">
                    <FormattedHTMLMessage id="profile.centre" />
                  </Col>
                  <Col className="col-md-7 col-6">{driver.centre}</Col>
                </Row>
                <Row>
                  <Col className="col-md-5 col-6 font-weight-bold">
                    <FormattedHTMLMessage id="profile.leads" />
                  </Col>
                  <Col className="col-md-7 col-6">
                    <FormattedNumber value={driver.leads} />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-5 col-6 font-weight-bold">
                    <FormattedHTMLMessage id="profile.volume" />
                  </Col>
                  <Col className="col-md-7 col-6">
                    <FormattedNumber value={driver.volume} />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-5 col-6 font-weight-bold">
                    <FormattedHTMLMessage id="profile.rank" />
                  </Col>
                  <Col className="col-md-7 col-6">
                    <FormattedNumber value={driver.rank} />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-5 col-6 font-weight-bold">
                    <FormattedHTMLMessage id="profile.rankChange" />
                  </Col>
                  <Col className="col-md-7 col-6">
                    {getRankingDifferenceIcon(driver.rankingDifference)}{' '}
                    <FormattedNumber value={driver.rankingDifference} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <DriverHistoryChart driver={driver} driverCount={driverCount} />
        </Wrapper>
      )}
    </div>
  );
};

export default DriverProfile;
