import { Driver } from './api/Driver';
import { InjectedIntl } from 'react-intl';

/**
 * Returns the ranking difference from current update to previous update
 * @param driver
 */
export function getRankingDifference(driver: Driver): number {
  if (driver.dataPoints.length < 2) {
    return 0;
  }
  const dataPointsCount = driver.dataPoints.length;

  return (
    driver.dataPoints[dataPointsCount - 2].rank -
    driver.dataPoints[dataPointsCount - 1].rank
  );
}

export function convertDataToDrivers(drivers: Driver[]): Driver[] {
  return drivers.map(driver => {
    const lastIndex = driver.dataPoints.length - 1;
    return {
      id: driver.id,
      rank: driver.dataPoints[lastIndex].rank,
      rankingDifference: getRankingDifference(driver),
      name: driver.name,
      centre: driver.centre,
      division: driver.division,
      leads: driver.dataPoints[lastIndex].leadCount,
      volume: driver.dataPoints[lastIndex].volumeAmount,
      // volume: intl.formatNumber(driver.dataPoints[0].volumeAmount, {
      //   // style: 'currency',
      //   // currencyDisplay: 'symbol',
      //   // currency: 'CAD',
      // }),
      dataPoints: driver.dataPoints,
    } as Driver;
  });
}

export function getLatestDate(drivers: Driver[]): Date {
  if (drivers.length === 0) {
    return new Date('2019-07-01T12:00:00Z');
  }
  const lastIndex = drivers[0].dataPoints.length - 1;
  return new Date(drivers[0].dataPoints[lastIndex].date);
}

export function getLocalizedColonSeparator(intl: InjectedIntl) {
  return intl.locale === 'fr' ? ' :' : ':';
}
