import React from 'react';
import styled, { keyframes } from 'styled-components';
import {
  bounceInUp,
  bounceInRight,
  fadeIn,
  fadeInUp,
  // lightSpeedIn,
} from 'react-animations';

const bounceInUpAnimation = keyframes`${bounceInUp}`;
const BounceInUpDiv = styled.div`
  animation: 1s ${bounceInUpAnimation};
`;
const BounceInUp: React.FunctionComponent<{}> = props => {
  return <BounceInUpDiv>{props.children}</BounceInUpDiv>;
};

const bounceInRightAnimation = keyframes`${bounceInRight}`;
const BounceInRightDiv = styled.div`
  animation: 1s ${bounceInRightAnimation};
`;
const BounceInRight: React.FunctionComponent<{}> = props => {
  return <BounceInRightDiv>{props.children}</BounceInRightDiv>;
};

const fadeInAnimation = keyframes`${fadeIn}`;
const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeIn: React.FunctionComponent<{}> = props => {
  return <FadeInDiv>{props.children}</FadeInDiv>;
};

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;
const FadeInUp: React.FunctionComponent<{}> = props => {
  return <FadeInUpDiv>{props.children}</FadeInUpDiv>;
};

// const lightSpeedInAnimation = keyframes`${lightSpeedIn}`;
// const LightSpeedInDiv = styled.div`
//   animation: 1s ${lightSpeedInAnimation};
// `;
// const LightSpeedIn: React.FunctionComponent<{}> = props => {
//   return <LightSpeedInDiv>{props.children}</LightSpeedInDiv>;
// };

export { BounceInRight, BounceInUp, FadeIn, FadeInUp };
