import './styles/App.scss';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Header from './components/Header';
import Footer from './components/Footer';
import SubHeader from './components/SubHeader';
import Leaderboard from './components/Leaderboard';
import styled from 'styled-components';
import { Driver } from './api/Driver';
import { getDrivers } from './api/Azure';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { BrowserRouter, Route } from 'react-router-dom';
import DriverProfile from './components/DriverProfile';
import { convertDataToDrivers } from './utils';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { BounceInUp, FadeIn } from './components/Animations';

const theme = createMuiTheme({
  palette: {
    primary: { main: 'rgba(51, 0, 0, 1)' }, // ups brown
    secondary: { main: 'rgba(255, 230, 115, 0.7)' }, // This is ups lt yellow
  },
});

const Wrapper = styled.div`
  // background-color: rgba(223, 220, 215, 0.3);
  padding-top: 2rem;
  background-image: url('/background.png');
  background-size: cover;
  background-position: right bottom;
`;

interface State {
  drivers: Driver[];
  lastUpdated: Date;
  isLoading: boolean;
  errorMessage: string | null;
}

interface AppProps {
  hideLoader?: () => void;
  showLoader?: () => void;
}

class App extends React.Component<AppProps & InjectedIntlProps, State> {
  state = {
    drivers: [],
    isLoading: true,
    errorMessage: null,
    lastUpdated: new Date('2019-06-19'),
  };

  async componentDidMount() {
    document.title = this.props.intl.formatMessage({ id: 'app.title' });
    this.props.hideLoader && this.props.hideLoader();
    try {
      const result = await getDrivers();
      const drivers = convertDataToDrivers(result);

      this.setState({
        drivers,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        errorMessage: error,
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Header />
            <SubHeader />
            <Wrapper>
              <Container>
                <Row>
                  <Col className="col-12">
                    <BounceInUp>
                      <Route
                        path="/"
                        exact
                        render={routeProps => (
                          <FadeIn>
                            <Leaderboard
                              {...routeProps}
                              drivers={this.state.drivers}
                            />
                          </FadeIn>
                        )}
                      />
                      <Route
                        path="/centres/:id"
                        exact
                        render={routeProps => (
                          <Leaderboard
                            {...routeProps}
                            drivers={this.state.drivers}
                            filterKey="centre"
                          />
                        )}
                      />
                      <Route
                        path="/divisions/:id"
                        exact
                        render={routeProps => (
                          <Leaderboard
                            {...routeProps}
                            drivers={this.state.drivers}
                            filterKey="division"
                          />
                        )}
                      />
                      <Route
                        path="/drivers/:id"
                        exact
                        render={routeProps => (
                          <FadeIn>
                            <DriverProfile
                              {...routeProps}
                              drivers={this.state.drivers}
                            />
                          </FadeIn>
                        )}
                      />
                    </BounceInUp>
                  </Col>
                </Row>
              </Container>
            </Wrapper>
            <Footer />
          </BrowserRouter>
        </ThemeProvider>
      </div>
    );
  }
}

export default injectIntl(App);
