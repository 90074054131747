import React from 'react';
import { Driver, DataPoint } from '../api/Driver';
import { Line } from 'react-chartjs-2';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { getLocalizedColonSeparator } from '../utils';

interface Props extends InjectedIntlProps {
  driver: Driver;
  driverCount: number;
}

function getDataset(driver: Driver, key: keyof DataPoint): object[] {
  if (driver) {
    const dataset = driver.dataPoints.map((datapoint: DataPoint) => {
      return { x: datapoint.date, y: datapoint[key] };
    });

    return dataset;
  }
  return [];
}

const DriverHistoryChart: React.FunctionComponent<Props> = (props: Props) => {
  const { driver, driverCount, intl } = props;
  const rankLabel = intl.formatMessage({ id: 'leaderboard.table.rank' });
  const leadsLabel = intl.formatMessage({ id: 'leaderboard.table.leads' });

  if (driver) {
    driver.dataPoints.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
  }
  const data = {
    datasets: [
      {
        label: rankLabel,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderColor: 'rgba(246, 139, 32, 1.00)',
        pointBorderColor: 'rgba(224, 183, 141, 1.00)',
        pointBackgroundColor: 'rgba(198, 125, 48, 1.00)',
        data: getDataset(driver, 'rank'),
        yAxisID: 'y-axis-rank',
      },
      {
        label: leadsLabel,
        backgroundColor: 'rgba(115, 188, 183, 0.5)',
        borderColor: 'rgba(63, 163, 158, 1)',
        pointBorderColor: 'rgba(115, 188, 183, 1)',
        pointBackgroundColor: 'rgba(36, 156, 189, 1)',
        data: getDataset(driver, 'leadCount'),
        yAxisID: 'y-axis-volume',
      },
    ],
  };

  const options = {
    legend: {
      display: SVGComponentTransferFunctionElement,
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem: any, data: any) {
          const datasetLabel =
            data.datasets[tooltipItem.datasetIndex].label || '';
          return (
            datasetLabel +
            getLocalizedColonSeparator(intl) +
            ' ' +
            intl.formatNumber(tooltipItem.yLabel)
          );
        },
        title: function(tooltipItem: any): string {
          return intl.formatDate(new Date(tooltipItem[0].label), {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }) as string;
        },
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          // distribution: 'series',
          time: {
            parser: 'YYYY-MM-DD',
            displayFormats: {
              millisecond: 'MMMM DD YYYY',
              second: 'MMMM DD YYYY',
              minute: 'MMMM DD YYYY',
              hour: 'MMMM DD YYYY',
              day: 'MMMM DD YYYY',
              week: 'MMMM DD YYYY',
              month: 'MMMM DD YYYY',
              quarter: 'MMMM DD YYYY',
              year: 'MMMM DD YYYY',
            },
          },
          ticks: {
            source: 'data',
            callback: function(value: string, index: number) {
              return intl.formatDate(new Date(value), {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              });
            },
          },
        },
      ],
      yAxes: [
        {
          type: 'linear',
          position: 'left',
          id: 'y-axis-rank',
          scaleLabel: {
            display: true,
            labelString: rankLabel,
            padding: 0,
            fontSize: 12,
          },
          // labels: {
          //   show: true,
          // },
          ticks: {
            beginAtZero: false,
            reverse: true,
            min: 1,
            max: driverCount,
            precision: 0,
            // stepSize: 1,
            userCallback: function(value: number, index: number, values: any) {
              return intl.formatNumber(value);
            },
          },
        },
        {
          type: 'linear',
          // display: true,
          position: 'right',
          id: 'y-axis-volume',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: leadsLabel,
            padding: 0,
            fontSize: 12,
          },
          // labels: {
          //   show: true,
          // },
          ticks: {
            beginAtZero: true,
            // stepSize: 1,
            precision: 0,
            userCallback: function(value: number, index: number, values: any) {
              return intl.formatNumber(value);
            },
          },
        },
      ],
    },
  };

  return <div>{driver && <Line data={data} options={options} />}</div>;
};

export default injectIntl(DriverHistoryChart);
