import React from 'react';
import Container from 'react-bootstrap/Container';

import Topnav from './Nav';

const Header: React.FunctionComponent = () => {
  return (
    <header className="App-header">
      <Container>
        <Topnav />
      </Container>
    </header>
  );
};

export default Header;
