import React from 'react';

const Decrease: React.FunctionComponent = () => {
  return (
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB7gAAAe4BoWPTtwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACfSURBVEiJ7Y2xDcIwFAXPpGIOyCqJswMFSPRBgjVAQAcDMEM8AXV6mIIgSlNgGmTHtgSi8ZX/2XeQSCR+jrAdt8iJgCMwDPQ8QM9r1OlzGDiqWYQc8zazDdZjw6WtGN+AMjCwrFGH4ICJnCWjTiAKv7zZuEZn4BW59kY0erVAOeXeQF/EyNe+/97AO1KR34EiRh7NjnK2R06/Lk4k/ssTfhQtjVc3rG4AAAAASUVORK5CYII="
      alt=""
    />
  );
};

export default Decrease;
