import React from 'react';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

interface Props {
  header: string;
  title: string;
  icon: string;
}

const Tab = styled.span`
  background-color: rgba(0, 133, 125, 1);
  color: white;
  text-align: left;
  padding: 5px 10px 2px;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 600;
`;

const Icon = styled.img`
  margin-right: 0.5rem;
`;

const CardModule: React.FunctionComponent<Props> = props => {
  return (
    <Card style={{ border: '0', backgroundColor: 'transparent' }}>
      <Card.Header
        style={{
          textAlign: 'left',
          padding: '0',
          backgroundColor: 'transparent',
          borderBottom: '0',
        }}
      >
        <Tab>
          <Icon src={props.icon} alt="" />
          {props.header}
        </Tab>
      </Card.Header>

      <Card.Body
        style={{
          padding: '1.25rem 0.65rem',
          backgroundColor: 'white',
          borderTop: 'rgba(0, 133, 125, 1) 3px solid',
          textAlign: 'left',
        }}
      >
        {props.title && <Card.Title>{props.title}</Card.Title>}
        <div>{props.children}</div>
      </Card.Body>
    </Card>
  );
};

export default CardModule;
