import React from 'react';

const Increase: React.FunctionComponent = () => {
  return (
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB7gAAAe4BoWPTtwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACfSURBVEiJY2AYBaNgeIHSdtmEsk7ZRFL0MBOrsKxTtoeRgaGTgYHB38aFn//onk87qWZBWadsD8N/hmIkIUtrF36+o3s+7aLYgtIO2W5GBoYSLFJEWYLXAjyGE20JTguIMBxhiSs/Ly5LsFpQ1ikXy8jA0E2E4TBgZe0q8ODono8X0SWYsKv/94+BgeEPCRb8Yfz/7y8J6kfBKBgF1AQAKo0wqCUoIr0AAAAASUVORK5CYII="
      alt=""
    />
  );
};

export default Increase;
