import React from 'react';
import Container from 'react-bootstrap/Container';

const Footer: React.FunctionComponent = () => {
  return (
    <footer className="App-footer">
      <Container />
    </footer>
  );
};

export default Footer;
