import React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';

const VideoBlock: React.FC<InjectedIntlProps> = ({ intl }) => {
  return (
    <div className={'embed-responsive embed-responsive-16by9'}>
      <video width="100%" controls>
        <source
          src={`https://media.chargeintosummer.ca/ups_charge_${
            intl.locale === 'fr' ? 'fr' : 'en'
          }.mp4`}
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default injectIntl(VideoBlock);
