import React from 'react';
import Logo from './Logo';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Topnav: React.FunctionComponent = () => {
  return (
    <Navbar expand>
      <Navbar.Brand>
        <Link className="navbar-brand" to="/">
          <Logo />
        </Link>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto justify-content-lg-end">
          <Nav.Item>
            <NavLink className="nav-link" exact to={'/'}>
              <FormattedMessage id="nav.home" />
            </NavLink>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Topnav;
